import React from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import queryString from "query-string";
import Chip from '@material-ui/core/Chip';
import Tour from 'reactour'
import moment from "moment";
import { get } from "lodash";
import Card from "@material-ui/core/Card";
import Snackbar from '@material-ui/core/Snackbar';
import Avatar from '@material-ui/core/Avatar';
import Alert from './Alert'
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import NextIcon from "@material-ui/icons/NavigateNext";
import ExitIcon from "@material-ui/icons/ExitToApp";
import CheckIcon from "@material-ui/icons/DoneOutlined";
import ScanIcon from "@material-ui/icons/CropFree";
import SendIcon from "@material-ui/icons/SendOutlined";
import QuestionIcon from "@material-ui/icons/HelpOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider} from 'notistack';
import { withSnackbar } from 'notistack';
import blue from "@material-ui/core/colors/blue";
import orange from "@material-ui/core/colors/orange";
import "./App.css";
//import { ReactComponent as QRCode } from './QRCode.svg';
import { ReactComponent as SecureServer } from "./SecureServer.svg";
import Skeleton from 'react-loading-skeleton';
import Meteor from "react-web-meteor"
Meteor.connect("wss://app.trumarks.org/websocket") //do this only once



const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: orange
  }
});

function scanCreate(source, data) {
  return fetch("/.netlify/functions/" + source, {
    method: "post",
    body: JSON.stringify({...data })
  })
    .then(res => res.json())
    .catch(err => err);
}

function scanUpdate(source, data) {
  return fetch("/.netlify/functions/" + source, {
    method: "post",
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .catch(err => err);
}

function getProduct(source, data) {
  return fetch("/.netlify/functions/" + source, {
    method: "post",
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .catch(err => err);
}

function sendEmail(source, data) {
  return fetch("/.netlify/functions/" + source, {
    method: "post",
    body: JSON.stringify(data)
  })
}

function StepOne(props) {

  function progressToStepTwo(){
    if (props.scanId && !props.demoMode){
      scanUpdate('scanUpdate', {_id: props.scanId, update: {isMyProductAnswer: true}})
    }
    props.changeStep(2)
  }
  function progressToStepThree(){
      if (props.scanId && !props.demoMode){
        scanUpdate('scanUpdate', {_id: props.scanId, update: {isMyProductAnswer: false}})
      }
      props.changeStep(3)
  }

  if (!props.data && props.isLoaded){
    // error case. Something  went wrong. Please re-scan your code.
    return <StepThree {...props}/>
  }

  if (!props.isLoaded){
    return [
      <Slide right duration={500} key="topzero">
        <div style={{ margin: "24px 0px" }}>
          <div style={{ maxWidth: "320px", margin: "auto" }}>
            <Typography variant="h5" gutterBottom>
              Is this your product?
            </Typography>

              <Card
                style={{
                  borderRadius: "20px",
                  marginTop: "16px"
                }}
              >
                <CardActionArea>
                  <Skeleton height={120}/>
                  <CardContent style={{ textAlign: "left" }}>
                    <Typography gutterBottom variant="subtitle1" component="h2">
                      <Skeleton/>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      gutterBottom
                    >
                      <Skeleton/>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      gutterBottom
                    >
                      <Skeleton/>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      gutterBottom
                    >
                      <Skeleton/>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
          </div>
        </div>
      </Slide>,
      <Slide right duration={500} key="bottomzero">
        <div>
          <Button
            size="large"
            style={{
              maxWidth: "400px",
              borderRadius: "30px",
              textTransform: "none",
              fontSize: "1.2em",
              margin: "0px 8px"
            }}
            variant="outlined"
            disabled
            onClick={() => props.changeStep(3)}
          >
            <CloseIcon style={{ marginRight: "4px" }} />
            No
          </Button>
          <Button
            size="large"
            disabled
            color="primary"
            style={{
              maxWidth: "400px",
              borderRadius: "30px",
              textTransform: "none",
              color: "white",
              fontSize: "1.2em",
              margin: "0px 8px"
            }}
            variant="contained"
            onClick={() => props.changeStep(2)}
          >
            <CheckIcon style={{ marginRight: "4px" }} />
            Yes
          </Button>
        </div>
      </Slide>
    ];
  }

  // regular case
  const productName = get(props, "data.productName");
  const productImage = get(props, "data.productImage");
  const batchStatus = get(props, "data.batchStatus");
  const state = get(props, "data.companyState");
  const dateTime = get(props, "data.initiateDate");
  const {code, codeReadable} = props
  const months = dateTime ?
    moment().diff(moment(dateTime), "months") + 1 : 1;
  return [
    <Slide right duration={500} key="topzero">
      <div style={{ margin: "24px 0px" }}>
        <div style={{ maxWidth: "320px", margin: "auto" }}>
          <Typography variant="h5" gutterBottom>
            Is this your product?
          </Typography>
          {productName && (
            <Card
              style={{
                borderRadius: "20px",
                marginTop: "16px"
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={productName}
                  style={{height: '35vh', objectFit: 'contain', width: '100%'}}
                  image={productImage}
                  title={productName}
                />
                <CardContent style={{ textAlign: "left" }}>
                  <Typography gutterBottom variant="subtitle1" component="h2">
                    {productName}
                  </Typography>
                  {state && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      gutterBottom
                    >
                      {`purchased in `}
                      <b>{state}</b>
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    gutterBottom
                  >
                    {state ? `within the last ` : `purchased within the last `}
                    <b>{months === 1 ? "month" : months + " months"}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    gutterBottom
                  >
                    {`label code matches `}
                    <b>{codeReadable}</b>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
        </div>
      </div>
    </Slide>,
    <Slide right duration={500} key="bottomzero">
      <div className="second-step">
        <Button
          size="large"
          style={{
            maxWidth: "400px",
            borderRadius: "30px",
            textTransform: "none",
            fontSize: "1.2em",
            margin: "0px 8px"
          }}
          variant="outlined"
          onClick={progressToStepThree}
        >
          <CloseIcon style={{ marginRight: "4px" }} />
          No
        </Button>
        <Button
          size="large"
          color="primary"
          style={{
            maxWidth: "400px",
            borderRadius: "30px",
            textTransform: "none",
            color: "white",
            fontSize: "1.2em",
            margin: "0px 8px"
          }}
          variant="contained"
          onClick={progressToStepTwo}
        >
          <CheckIcon style={{ marginRight: "4px" }} />
          Yes
        </Button>
      </div>
    </Slide>
  ];
}

class StepTwo extends React.Component {
  clickedLearnMore(){
      if (this.props.scanId && !this.props.demoMode){
        scanUpdate('scanUpdate', {_id: this.props.scanId, update: {clickedToEngage: true}})
      }
  }

  renderMailChimpForm(){
    return (
      <div id="mc_embed_signup" style={{clear: "left", font: "14px Helvetica,Arial,sans-serif"}}>
        <form action="https://antheapro.us4.list-manage.com/subscribe/post?u=622dec3aa88c942c6935c6ed5&amp;id=64edfedaee" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
          <div id="mc_embed_signup_scroll">
            <h2>Schedule a Demo</h2>
            <div className="mc-field-group">
              <label for="mce-CNAME">Company Name <span className="asterisk">*</span></label>
              <input type="text" defaultValue="" name="CNAME" className="" id="mce-CNAME" />
            </div>
            <div className="mc-field-group">
              <label for="mce-FNAME">First Name <span className="asterisk">*</span></label>
              <input type="text" defaultValue="" name="FNAME" className="" id="mce-FNAME" />
            </div>
            <div className="mc-field-group">
              <label for="mce-LNAME">Last Name <span className="asterisk">*</span></label>
              <input type="text" defaultValue="" name="LNAME" className="" id="mce-LNAME" />
            </div>
            <div className="mc-field-group">
              <label for="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
        	    <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
            </div>
            <div className="mc-field-group size1of2">
            	<label for="mce-PHONE">Phone Number </label>
            	<input type="text" name="PHONE" className="" defaultValue="" id="mce-PHONE" />
            </div>
          	<div id="mce-responses" className="clear">
          		<div className="response" id="mce-error-response" style={{display: 'none'}}></div>
          		<div className="response" id="mce-success-response" style={{display: 'none'}}></div>
          	</div>
            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
              <input type="text" name="b_622dec3aa88c942c6935c6ed5_64edfedaee" tabIndex="-1" defaultValue="" />
            </div>
            <div className="clear">
              <input type="submit" defaultValue="Submit Information" name="subscribe" id="mc-embedded-subscribe" className="button" />
            </div>
          </div>
        </form>
      </div>
    )
  }

  render(){
    const url = get(this.props, 'data.productLink')
    return (
      <div>
      <Slide right duration={500} key="toptwo">
        <div style={{ margin: "24px 0px" }}>
          <div style={{ maxWidth: "320px", margin: "auto" }}>
            <Typography variant="h5" gutterBottom>
              Your product is authentic!
            </Typography>
            <Zoom delay={300} duration={500}>
              <img
                style={{height: '100px', width: '100px'}}
                alt="Trumarks favicon"
                src="/trumarksfavicongreen.png"
              />
            </Zoom>
          </div>
        </div>
      </Slide>
      {this.props.isBogus ? (
        <div key="bottomtwo" className="third-step-a">
        <Slide right duration={500} >
          <Typography variant="body1" color="textSecondary" gutterBottom>
            1. Link to lab results:
          </Typography>
          <Button
            color="primary"
            size="large"
            style={{
              width: "100%",
              maxWidth: "400px",
              borderRadius: "30px",
              textTransform: "none",
              fontSize: "1.2em"
            }}
            target="_blank"
            variant="outlined"
            href={"https://analytical360.com/testresults?perpage=10&tab=Concentrate"}
          >
            View Lab Results
            <ExitIcon style={{marginLeft: '4px'}}/>
          </Button>
          <br/>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            2. Link to your brand events:
          </Typography>
          <Button
            color="primary"
            size="large"
            style={{
              width: "100%",
              maxWidth: "400px",
              borderRadius: "30px",
              textTransform: "none",
              fontSize: "1.2em"
            }}
            target="_blank"
            variant="outlined"
            href={"https://www.defonce.com/events"}
          >
            View Event Calendar
            <ExitIcon style={{marginLeft: '4px'}}/>
          </Button>
          <br/>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            3. Link to consumer education material:
          </Typography>
          <Button
            color="primary"
            size="large"
            style={{
              width: "100%",
              maxWidth: "400px",
              borderRadius: "30px",
              textTransform: "none",
              fontSize: "1.2em"
            }}
            variant="outlined"
            target="_blank"
            href={"https://s3.amazonaws.com/leafly-s3/content/infographic-what-are-cannabis-terpenes-and-how-do-they-affect-you/jXSS0pS1Sw2p2eq176GL_Leafly-Cannabis-Terpene-Wheel-Infographic.png"}
          >
            Education Material
            <ExitIcon style={{marginLeft: '4px'}}/>
          </Button>
        </Slide>
        </div>
      ) : (
        <div key="bottomtwo" className="third-step-a">
        <Slide right duration={500} >
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Learn more about your product at the manufacturer's website.
          </Typography>
          <Button
            color="primary"
            size="large"
            style={{
              width: "100%",
              maxWidth: "400px",
              borderRadius: "30px",
              textTransform: "none",
              color: "white",
              fontSize: "1.2em"
            }}
            variant="contained"
            onClick={this.clickedLearnMore}
            href={url || "https://trumarks.org"}
          >
            Learn more
            <ExitIcon style={{marginLeft: '4px'}}/>
          </Button>
        </Slide>
        </div>
      )}
      <div className="third-step-b" style={{marginTop: '280px'}}>
      <a
        href={"https://trumarks.org"}
        target="_blank"
        rel="noopener noreferrer"
        className="no-style-link"
      >
        <Fade duration={1000}>
          <img
            alt="Trumarks logo"
            src="/TrumarksPoweredByLogoFinal.png"
            style={{
              marginBottom: "8px",
              color: "#90caf9",
              marginTop: "0px",
              height: "40px"
            }}
          />
        </Fade>
      </a>
      {this.renderMailChimpForm()}
      </div>
      </div>
    )
  }
}


class StepFour extends React.Component {
  componentDidMount(){
    if (this.props.scanId && !this.props.demoMode){
      scanUpdate('scanUpdate', {_id: this.props.scanId, update: {recallScreenShown: true}})
    }

  }
  clickedLearnMore(){
      if (this.props.scanId && !this.props.demoMode){
        scanUpdate('scanUpdate', {_id: this.props.scanId, update: {clickedToEngage: true}})
      }
  }

  render(){
    const productName = get(this.props, "data.productName");
    const productImage = get(this.props, "data.productImage");
    const batchStatus = get(this.props, "data.batchStatus");
    const recallMessage = get(this.props, "data.recallMessage");
    const state = get(this.props, "data.companyState");
    const dateTime = get(this.props, "data.initiateDate");
    const {code, codeReadable} = this.props
    const months = dateTime ?
      moment().diff(moment(dateTime), "months") + 1 : 1;
    const url = get(this.props, 'data.productLink')
    return [
      <Slide right duration={500} key="toptwo">
        <div style={{ margin: "24px 0px" }} className="fifth-step">
          <div style={{ maxWidth: "320px", margin: "auto" }}>
            <Alert message={"Manufacturer Recall"}  variant="warning" />
            <Typography variant="body2" gutterBottom style={{marginTop: '8px'}}>
              Please bring your product and packaging back to the dispensary.
            </Typography>
                <div style={{ margin: "24px 0px" }}>
                  <div style={{ maxWidth: "320px", margin: "auto" }}>
                    {productName && (
                      <Card
                        style={{
                          borderRadius: "20px",
                          marginTop: "16px"
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            alt={productName}
                            style={{height: '20vh', objectFit: 'contain', width: '100%'}}
                            image={productImage}
                            title={productName}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="h2">
                              {productName}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              gutterBottom
                            >
                              <b>{codeReadable}</b>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    )}
                  </div>
                </div>
          </div>
        </div>
      </Slide>,
      <div key="bottomtwo">
      <Slide right duration={500} >
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Message from the brand:
      </Typography>
      <Alert message={recallMessage} icon={null} />
        <Button
          color="primary"
          size="large"
          style={{
            width: "100%",
            maxWidth: "400px",
            borderRadius: "30px",
            textTransform: "none",
            color: "white",
            fontSize: "1.2em",
            marginTop: '36px'
          }}
          variant="contained"
          onClick={this.clickedLearnMore}
          href={url || "https://trumarks.org"}
        >
          Learn more
          <ExitIcon style={{marginLeft: '4px'}}/>
        </Button>
      </Slide>
      </div>
    ];
  }
}

class StepThreeWithout extends React.Component {
  componentDidMount(){
    if (this.props.scanId && !this.props.demoMode){
      scanUpdate('scanUpdate', {_id: this.props.scanId, update: {errorScreenShown: true}})
    }
  }
  state = {
    sending: false,
  };
  render(){
    return [
      <Slide right duration={500} key="topthreewithout">
        <div style={{ margin: "24px 0px" }}>
          <div style={{ maxWidth: "320px", margin: "auto" }}>
            <Typography variant="h5" gutterBottom>
              Hmm... Something isn't right
            </Typography>
            <Zoom delay={300} duration={500}>
              <QuestionIcon style={{height: '100px', width: '100px', color: '#f44336'}} />
            </Zoom>
          </div>
        </div>
      </Slide>,

      <Slide right duration={500} key="middlethreewithout">
        <Typography variant="h6" gutterBottom>
          Please try the following:
        </Typography>
        <ul style={{textAlign: 'left'}}>
          <li>
          <Typography variant="body2" color="textSecondary">
            Make sure the Trumarks is legible
          </Typography>
          </li>
          <li>
          <Typography variant="body2" color="textSecondary">
            Make sure there are no other labels covering the Trumarks
          </Typography>
          </li>
          <li>
          <Typography variant="body2" color="textSecondary">
            If you are scanning through a glass display, please ask your budtender to see the product
          </Typography>
          </li>
          <li>
          <Typography variant="body2" color="textSecondary">
            {`Make sure that the code on the label is `}
            <b>{this.props.codeReadable}</b>
          </Typography>
          </li>
        </ul>
      </Slide>,
      <Slide right duration={500} key="bottomthreewithout">
        <div className="fourth-step">
          <Chip
            avatar={
              <Avatar>
                <ScanIcon />
              </Avatar>
            }
            label="Close this window to retry scan."
            style={{
              maxWidth: "400px",
              textTransform: "none",
            }}
            color="primary"
          />

          <Button
            style={{
              maxWidth: "400px",
              borderRadius: "30px",
              textTransform: "none",
              margin: "16px 8px 8px 8px"
            }}
            variant="outlined"
            disabled={this.state.sending}
            onClick={() => {
              this.setState({sending: true})
              if (!this.props.demoMode){
                sendEmail('sendEmail', {code: this.props.code})
                scanUpdate('scanUpdate', {_id: this.props.scanId, update: {errorReportSent: true}})
              }
              setTimeout(() => {
                this.setState({sending: false})
                this.props.enqueueSnackbar('Error report sent. Thank you.', {
                    variant:'success',
                    autoHideDuration: 1000,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    }
                  });
              }, 750)
            }}
          >
            {this.state.sending ? "Sending..." : "Send error report"}
            <SendIcon style={{marginLeft: '4px'}}/>
          </Button>
        </div>
      </Slide>
    ];
  }
}

const StepThree = withSnackbar(StepThreeWithout)

function StepZero(props) {
  function progressToStepOne(){
    if (props.scanId && !props.demoMode){
      scanUpdate('scanUpdate', {_id: props.scanId, update: {over21: true}})
    }
    props.changeStep(1)
  }
  return [
    <Slide right duration={500} key="topzero">
      <div style={{ margin: "24px 0px" }}>
        <div style={{ maxWidth: "320px", margin: "auto" }}>
          <Typography variant="h5" gutterBottom>
            You just scanned the Trumarks!
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Your product should be real and trusted. Let us check this for you.
          </Typography>
          <SecureServer
            style={{
              height: "calc(27vh)",
              width: "280px",
              margin: "24px 0px"
            }}
          />
        </div>
      </div>
    </Slide>,
    <Slide right duration={500} key="bottomzero">
      <Button
        color="primary"
        size="large"
        className="first-step"
        style={{
          width: "100%",
          maxWidth: "400px",
          borderRadius: "30px",
          textTransform: "none",
          color: "white",
          fontSize: "1.2em"
        }}
        variant="contained"
        onClick={progressToStepOne}
      >
        I am over 21
        <NextIcon />
      </Button>
    </Slide>
  ];
}

class App extends React.Component {
  state = {
    data: null,
    isLoaded: false,
    error: false,
    step: 0,
    scanId: null,
    isTourOpen: false,
    isBogus: false
  };
  code = null;
  demoMode = true;
  componentDidMount() {
    const parsed = queryString.parse(window.location.search);
    const codeParsed = parsed ? (parsed.Code || parsed.code) : null
    const productIdParsed = parsed ? (parsed.ProductId || parsed.productId) : null
    const batchIdParsed = parsed ? (parsed.BatchId || parsed.batchId) : null

    this.code = null
    this.codeReadable = null
    this.demoMode = true

    if (codeParsed) {
      this.demoMode = false
      // do some fancy dance to insert dashes if necessary
      this.code = codeParsed.replace(/-/g, '')
      this.codeReadable = this.code.slice(0,4) + "-" + this.code.slice(4,8) + "-" + this.code.slice(8)
      Meteor.call("getProductInfoFromCode", {code: this.code, demo: true}, (err, res) => {
        if (err) {
          console.log('getProduct API error: ', err)
          this.setState({isLoaded: true, error: err})
        } else {
          this.setState({
            isLoaded: true,
            data: res
          });
        }

      })

      fetch("https://ipapi.co/json")
      .then((response) => response.json())
      .then((ipData) => {

        scanCreate('scanCreate', {code: this.code, ...ipData})
          .then(response => {
            const scanId = response.data._id
            this.setState({scanId})
          })
          .catch(err => console.log('ScanCreate API error: ', err))
      });

    } else if (batchIdParsed) {
      this.code = 'xxxxxxxxxxxx'
      this.codeReadable = "xxxx-xxxx-xxxx"
      Meteor.call("getProductInfoFromBatch", {batchId: batchIdParsed}, (err, res) => {
        if (err) {
          console.log('getProductFromBatch API error: ', err)
          this.setState({isLoaded: true, error: err})
        } else {
          this.setState({
            isLoaded: true,
            data: res
          });
        }
      })
    } else if (productIdParsed) {
      this.code = 'xxxxxxxxxxxx'
      this.codeReadable = "xxxx-xxxx-xxxx"
      Meteor.call("getProductInfoFromProduct", {productId: productIdParsed}, (err, res) => {
        if (err) {
          console.log('getProductInfoFromProduct API error: ', err)
          this.setState({isLoaded: true, error: err})
        } else {
          this.setState({
            isLoaded: true,
            data: res
          });
        }
      })
    } else {
      this.code = 'xxxxxxxxxxxx'
      this.codeReadable = "xxxx-xxxx-xxxx"
      Meteor.call("getProductInfoFromCode", {code: 'bogus', demo: true}, (err, res) => {
        if (err) {
          console.log('getProduct API error: ', err)
          this.setState({isLoaded: true, error: err})
        } else {
          this.setState({
            isLoaded: true,
            data: res,
            isBogus: true
          });
        }

      })
    }
    setTimeout(() => {
      this.setState({isTourOpen: true})
    }, 750)
  }
  componentDidUpdate(prevProps, prevState){

    // one off case for recaall in demo mode
    if (this.demoMode && !prevState.isLoaded && this.state.isLoaded && this.state.data){
      if(this.state.data.batchStatus === "recalled"){
        this.changeStep(4)
      }
    }
    if ((!prevState.scanId || !prevState.isLoaded) && (this.state.scanId && this.state.isLoaded && this.state.data)){
      const {
        batchId,
        productId,
        companyId,
        facilityId,
        productName,
        stateTrackAndTraceId,
        batchQuantity,
        batchStatus,
        initiateDate,
        productSku,
        productLink,
        companyName,
        facilityName,
        brandName
       } = this.state.data
       const acceptableStatuses = ["printed", "recalled"]
       if (!this.demoMode || (this.demoMode && !!batchStatus)){
         if (!acceptableStatuses.includes(batchStatus)){
           this.changeStep(3)
         }
       }
       if (batchStatus === "recalled"){
         this.changeStep(4)
       }

      if (!this.demoMode){
        scanUpdate('scanUpdate', {_id: this.state.scanId, update: {
          batchId,
          productId,
          companyId,
          facilityId,
          batchId,
          productName,
          stateTrackAndTraceId,
          batchQuantity,
          batchStatus,
          initiateDate,
          productSku,
          productLink,
          companyName,
          facilityName,
          brandName
        }}, () => {
          Meteor.call('checkForAlert', {scanId: this.state.scanId})
        })
      }

    }
    if (!prevState.scanId && this.state.scanId && this.state.step !== 0){
      if (!this.demoMode){
        scanUpdate('scanUpdate', {_id: this.state.scanId, update: {over21: true}})
      }
    }
  }
  closeTour = () => {
    this.setState({isTourOpen: false})
  }
  changeStep = step => {
    this.setState({ step, isTourOpen: false });
    setTimeout(() => this.setState({isTourOpen: true}), 1000)
  };
  renderStep() {
    const { step, ...other } = this.state;
    if (step === 0) {
      return <StepZero {...other} changeStep={this.changeStep} code={this.code} demoMode={this.demoMode} codeReadable={this.codeReadable}/>;
    }

    if (step === 1) {
      return <StepOne {...other} changeStep={this.changeStep} code={this.code} demoMode={this.demoMode} codeReadable={this.codeReadable}/>;
    }

    // success
    if (step === 2) {
      return <StepTwo {...other} changeStep={this.changeStep} code={this.code} demoMode={this.demoMode} codeReadable={this.codeReadable} isBogus={this.state.isBogus}/>;
    }

    //failure
    if (step === 3) {
      return <StepThree {...other} changeStep={this.changeStep} code={this.code} demoMode={this.demoMode} codeReadable={this.codeReadable} />;
    }

    //recall
    if (step === 4) {
      return <StepFour {...other} changeStep={this.changeStep} code={this.code} demoMode={this.demoMode} codeReadable={this.codeReadable}/>;
    }
  }
  render() {
    let steps = []
    switch(this.state.step) {
      case 0:
        steps = [
          {
            selector: '.first-step',
            content: `To stay on the right side of the law, we first ask your customer (let's call her Sarah) to verify her age.`,
            position: 'bottom'
          },
        ]
        break;
      case 1:
        steps = [
          {
            selector: '.second-step',
            content: 'Next, Sarah verifies her product label matches what she sees above. Remember - each barcode is 100% unique.',
            position: 'bottom'
          },
        ]
        break;
      case 2:
        if (this.state.isBogus) {
          steps = [
            {
              selector: '.third-step-a',
              content: "Now, Trumarks acts as a switchboard for you to communicate with Sarah about your brand. Here are a few examples of where you could send Sarah.",
              position: 'bottom'
            },
            {
              selector: '.third-step-b',
              content: "Want to learn more? Share your contact information with us and we'll reach out to schedule a demo with you.",
              position: 'top'
            },
          ]

        } else {
          steps = [
            {
              selector: '.third-step-a',
              content: "Now, Trumarks acts as a tool for you to communicate with Sarah about your brand. Link Sarah to a product page, a Facebook events list, lab results, a video of the production process, a customer survey, or wherever you want!",
              position: 'bottom'
            },
            {
              selector: '.third-step-b',
              content: "Want to learn more? Share your contact information with us and we'll reach out to schedule a demo with you.",
              position: 'top'
            },
          ]

        }
        break;
      case 3:
        steps = [
          {
            selector: '.fourth-step',
            content: 'Something is wrong and Sarah is instructed to send an error report.',
            position: 'bottom'
          },
        ]
        break;
      case 4:
        steps = [
          {
            selector: '.fifth-step',
            content: 'If you have issued a withdrawal or recall for this lot, Sarah is informed and can take the proper next steps.',
            position: 'top'
          },
        ]
        break;
      default:
        // code block
    }

    return (
      <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <div
          style={{ textAlign: "center", flex: 1, padding: "24px" }}
          className="site-background"
        >
          <div
            className="customflexvertical"
            style={{
              justifyContent: "space-around",
              WebkitJustifyContent: "space-around",
              maxWidth: '320px', margin: 'auto',
              marginTop: "0px"
            }}
          >
            <a
              href={"https://trumarks.org"}
              target="_blank"
              rel="noopener noreferrer"
              className="no-style-link"
            >
              <Fade duration={1000}>
                <img
                  alt="Trumarks logo"
                  src="/TrumarksPoweredByLogoFinal.png"
                  style={{
                    marginBottom: "8px",
                    color: "#90caf9",
                    marginTop: "0px",
                    height: "40px"
                  }}
                />
              </Fade>
            </a>
            {this.renderStep()}

          </div>
        </div>
        <Tour
          lastStepNextButton={<Button variant={"outlined"} color="secondary">Done!</Button>}
          badgeContent={(curr, tot) => `Demo`}
          steps={steps}
          showCloseButton={false}
          disableDotsNavigation={this.state.step === 2 ? false : true}
          showButtons={this.state.step === 2 ? true : false}
          showNavigation={this.state.step === 2 ? true : false}
          showNavigationNumber={false}
          accentColor={"#ff9800"}
          scrollDuration={250}
          rounded={20}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour}
          update={this.state.step.toString()}
        />

        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

export default App;
